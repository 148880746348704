<nav>
    <ul>
        <!-- <li><a (click)="router.navigate(['./path-view'])">PFADANSICHT</a></li> -->
        <li><a (click)="router.navigate(['./new-path'])">STAMMDATEN</a></li>
        <li><a (click)="router.navigate(['./entry-kis'])">EINTRAG KIS</a></li>
        <li><a style="background-color: #6b8294;" (click)="router.navigate(['./patient-archive'])">EINTRAG
                PATIENTENARCHIV</a></li>
    </ul>
</nav>

<div class="container">

    <button (click)="onNewDocumentClick()" style="color: white;" mat-button id="create-document">
        <mat-icon style="margin-right: 5%;" class="material-symbols-rounded">add_circle</mat-icon>
        Dokument hinzufügen
    </button>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="ecmDocumentTypeName"
            matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="ecmDocumentTypeName">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef
                    mat-sort-header="ecmDocumentTypeName" sortActionDescription="Sort by ecmDocumentTypeName">
                    DOKUMENTENTYP
                </th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span *ngIf="element.ecmDocumentTypeName != undefined">{{element.ecmDocumentTypeName}}</span>
                        <span *ngIf="element.ecmDocumentTypeName == undefined">{{'Akte nicht archiviert'}}</span>
                        <button (click)="loadDocument(element)" style="color: white;" mat-button id="loadActivity">
                            DOKUMENT EINSICHT
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="SFID">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by SFID" mat-sort-header="SFID">FALL-ID</th>
                <td mat-cell *matCellDef="let element">{{element.SFID}}
                </td>
            </ng-container>

            <ng-container matColumnDef="archiveDate">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by archiveDate" mat-sort-header="archiveDate">DATUM AUF DEM
                    DOKUMENT
                </th>
                <ng-container>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.archiveDate != undefined">{{this.pipe.transform(element.archiveDate,
                            'dd.MM.yyyy')}}</span>
                        <span *ngIf="element.archiveDate == undefined">{{'Datum nicht vorhanden'}}</span>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="letzte_aenderung">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by letzte_aenderung" mat-sort-header="letzte_aenderung">LETZTE ÄNDERUNG
                </th>
                <td mat-cell *matCellDef="let element">{{this.pipe.transform(element.letzte_aenderung, 'dd.MM.yyyy hh:mma')}}
                </td>
            </ng-container>

            <!-- action column -->
            <ng-container matColumnDef="delete">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>AKTION</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <!-- edit -->
                    <button style="margin-left: 0%; background-color: white;" mat-icon-button
                        (click)="loadDocument(element)">
                        <mat-icon style="color: #6b8294;">edit</mat-icon>
                    </button>
                    <!-- delete -->
                    <button style="margin-left: 0%; background-color: white;" mat-icon-button
                        (click)="popupText='Möchten sie das Dokument wirklich löschen?'; showPopupDelete = true; elementToDelete = element.id;">
                        <mat-icon style="color: #6b8294;">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <mat-paginator [length]="documents.length" [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>

    <app-popup (buttonRightClicked)="navToCreateDocument()" class="popup" *ngIf="showPopup == true"
        (answerEvent)="answerEvent($event)" [popupText]="popupText" [selectOptions]="sfids"
        (optionSelected)="onOptionSelected($event)" [buttonLeftText]="'ABBRECHEN'" [buttonRightText]="'OK'"
        [buttonCenterText]="''"></app-popup>

    <app-popup class="popup" *ngIf="showPopupDelete == true" (buttonRightClicked)="deleteDocument()"
        (answerEvent)="answerEvent($event)" [popupText]="popupText" [buttonLeftText]="'ABBRECHEN'"
        [buttonRightText]="'OK'"></app-popup>

</div>

<!-- <div class="no-document-container" *ngIf="documents.length === 0">

    <button (click)="onNewDocumentClick()" style="color: white;" mat-button id="no-document-create-document">
        <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
        Dokumente hinzufügen
    </button>

</div> -->