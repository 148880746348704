/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { DownloadDropdownDTO } from '../model/downloadDropdownDTO';
 import { DownloadFilterRequest } from '../model/downloadFilterRequest';
 import { DownloadPatientPathRequest } from '../model/downloadPatientPathRequest';
 import { PatientPathDownloadDTO } from '../model/patientPathDownloadDTO';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class DownloadService {
 
     protected basePath = 'https://dev.sodiaph.de/api';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadDropdownsGet(observe?: 'body', reportProgress?: boolean): Observable<DownloadDropdownDTO>;
     public v1DownloadDropdownsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DownloadDropdownDTO>>;
     public v1DownloadDropdownsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DownloadDropdownDTO>>;
     public v1DownloadDropdownsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<DownloadDropdownDTO>('get',`${this.basePath}/v1/download/dropdowns`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadEcmEntryCsvPost(body: DownloadPatientPathRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public v1DownloadEcmEntryCsvPost(body: DownloadPatientPathRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public v1DownloadEcmEntryCsvPost(body: DownloadPatientPathRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public v1DownloadEcmEntryCsvPost(body: DownloadPatientPathRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling v1DownloadEcmEntryCsvPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/csv'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request('post',`${this.basePath}/v1/download/ecm_entry_csv`,
             {
                 body: body,
                 responseType: "blob",
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadFilteredPatientPathsPost(body: DownloadFilterRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<PatientPathDownloadDTO>>;
     public v1DownloadFilteredPatientPathsPost(body: DownloadFilterRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PatientPathDownloadDTO>>>;
     public v1DownloadFilteredPatientPathsPost(body: DownloadFilterRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PatientPathDownloadDTO>>>;
     public v1DownloadFilteredPatientPathsPost(body: DownloadFilterRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling v1DownloadFilteredPatientPathsPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<Array<PatientPathDownloadDTO>>('post',`${this.basePath}/v1/download/filtered_patient_paths`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadHisEntryCsvPost(body: DownloadPatientPathRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public v1DownloadHisEntryCsvPost(body: DownloadPatientPathRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public v1DownloadHisEntryCsvPost(body: DownloadPatientPathRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public v1DownloadHisEntryCsvPost(body: DownloadPatientPathRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling v1DownloadHisEntryCsvPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/csv'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request('post',`${this.basePath}/v1/download/his_entry_csv`,
             {
                 body: body,
                 responseType: "blob",
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadIcdCsvPost(body: DownloadPatientPathRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public v1DownloadIcdCsvPost(body: DownloadPatientPathRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public v1DownloadIcdCsvPost(body: DownloadPatientPathRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public v1DownloadIcdCsvPost(body: DownloadPatientPathRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling v1DownloadIcdCsvPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/csv'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request('post',`${this.basePath}/v1/download/icd_csv`,
             {
                 body: body,
                 responseType: "blob",
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadPatientPathCsvPost(body: DownloadPatientPathRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public v1DownloadPatientPathCsvPost(body: DownloadPatientPathRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public v1DownloadPatientPathCsvPost(body: DownloadPatientPathRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public v1DownloadPatientPathCsvPost(body: DownloadPatientPathRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling v1DownloadPatientPathCsvPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/csv'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request('post',`${this.basePath}/v1/download/patient_path_csv`,
             {
                 body: body,
                 responseType: "blob",
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public v1DownloadTreatmentUnitCsvPost(body: DownloadPatientPathRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public v1DownloadTreatmentUnitCsvPost(body: DownloadPatientPathRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public v1DownloadTreatmentUnitCsvPost(body: DownloadPatientPathRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public v1DownloadTreatmentUnitCsvPost(body: DownloadPatientPathRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (body === null || body === undefined) {
             throw new Error('Required parameter body was null or undefined when calling v1DownloadTreatmentUnitCsvPost.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/csv'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request('post',`${this.basePath}/v1/download/treatment_unit_csv`,
             {
                 body: body,
                 responseType: "blob",
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 