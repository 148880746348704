<nav>
    <ul>
        <li><a (click)="router.navigate(['./downloads'])">DOWNLOADS</a></li>
    </ul>
</nav>
<div class="container">

    <mat-form-field>
        <mat-label for="search">Suchen</mat-label>
        <input #SIDSearchInput (input)="fetchPaths()" id="search" matInput type="text" name="search">
    </mat-form-field>

    <button (click)="onNewPathClick()" style="color: white;" mat-button (click)="router.navigate(['./new-path'])"
        id="createUser">
        <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
        Patientenpfad anlegen
    </button>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort
            (matSortChange)="announceSortChange($event)" matSortActive="SID" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="SID">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="SID"
                    sortActionDescription="Sort by SID">PATIENTENPFAD</th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>{{element.SID}}</span>
                        <button (click)="loadPath(element)" style="color: white;" mat-button id="loadPath"
                            [disabled]="isPathClosed[element.SID]">
                            PFAD EINSICHT
                        </button>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="updated">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="updated"
                    sortActionDescription="Sort by updated">LETZTE ÄNDERUNG</th>
                <td mat-cell *matCellDef="let element">{{this.pipe.transform(element.updated, 'dd.MM.yyyy hh:mma',
                    'GMT')}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="status"
                    sortActionDescription="Sort by sttaus">STATUS</th>
                <td mat-cell *matCellDef="let element">{{ pathStatus[element.SID] }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="action"
                    sortActionDescription="Sort by sttaus">KORREKTUROPTIONEN</th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="closePath(element)" style="color: white;" mat-button id="closePath"
                        [disabled]="isPathClosed[element.SID]">
                        PFAD VOLLSTÄNDIG ERFASST
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{ 'disabled-row': isPathClosed[row.SID] }"></tr>
        </table>
    </div>

    <mat-paginator [length]="paths.length" [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>

</div>

<!-- <div class="no-path-container" *ngIf="paths.length === 0">

    <button (click)="onNewPathClick()" style="color: white;" mat-button (click)="router.navigate(['./new-path'])"
        id="no-path-create-user">
        <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
        Patientenpfade anlegen
    </button>

</div> -->

<app-popup class="popup" *ngIf="showPopup == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
    [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>