<nav>
    <ul>
        <!-- <li><a (click)="router.navigate(['./path-view'])">PFADANSICHT</a></li> -->
        <li><a style="background-color: #6b8294;" routerLinkActive="active"
                (click)="router.navigate(['./new-path'])">STAMMDATEN</a></li>
        <li><a (click)="editedInputs[path.id] ? handleUnsavedChanges() : router.navigate(['./entry-kis'])">EINTRAG
                KIS</a></li>
        <li><a style="width: 117%;"
                (click)="editedInputs[path.id] ? handleUnsavedChanges() : router.navigate(['./patient-archive'])">EINTRAG
                PATIENTENARCHIV</a></li>
    </ul>
</nav>

<div class="container">

    <!-- create path -->
    <form #pathForm="ngForm" name="pathForm" (ngSubmit)="onSubmit(pathForm)">
        <!-- SID -->
        <mat-form-field style="width: 10%;">
            <mat-label>SODIAPH-ID</mat-label>
            <input disabled="disabled" style="opacity: 0.5;" readonly id="sodiaphID" name="SID" matInput
                [(ngModel)]="path.SID">
        </mat-form-field>

        <!-- gender -->
        <mat-form-field style="width: 12%;">
            <mat-label>Geschlecht</mat-label>
            <mat-select required (ngModelChange)="editedInputs[path.id] = true;" class="form-control" id="geschlecht"
                matNativeControl name="gender" [(ngModel)]="path.gender">
                <mat-option *ngFor="let gender of genders" [value]="gender">{{ gender }}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- admissionDate -->
        <mat-form-field style="width: 21%;">
            <mat-label>Startdatum Klinikaufenthalt</mat-label>
            <input placeholder="TT.MM.JJJJ" (ngModelChange)="onAdmissionDateChange($event)"
                (focusin)="onAdmissionDateFocusIn($event)" (focusout)="onAdmissionDateFocusOut($event)"
                (keydown.enter)="$event.preventDefault()" required id="aufenthalt" name="admissionDate" matInput
                [(ngModel)]="path.admissionDate" [max]="getCurrentDate()" [matDatepicker]="admissionDate">
            <mat-datepicker-toggle matIconSuffix [for]="admissionDate"></mat-datepicker-toggle>
            <mat-datepicker #admissionDate></mat-datepicker>
        </mat-form-field>

        <!-- birthdate -->
        <mat-form-field style="width: 14%;">
            <mat-label>Geburtsdatum</mat-label>
            <input placeholder="DD.MM.JJJJ" (keydown.enter)="$event.preventDefault()" [max]="getCurrentDate()"
                (ngModelChange)="editedInputs[path.id] = true;" id="geb" name="birthDate" matInput
                [(ngModel)]="path.birthDate" [matDatepicker]="birthdate" (dateInput)="calculateAge($event.target.value)"
                (ngModelChange)="calculateAge($event)">
            <mat-datepicker-toggle matIconSuffix [for]="birthdate"></mat-datepicker-toggle>
            <mat-datepicker #birthdate></mat-datepicker>
        </mat-form-field>

        <!-- age -->
        <mat-form-field style="width: 8%;">
            <mat-label>Alter</mat-label>
            <input (keydown.enter)="$event.preventDefault()" [min]="0" (ngModelChange)="editedInputs[path.id] = true;"
                required type="number" id="alter" name="age" matInput [(ngModel)]="path.age">
        </mat-form-field>

        <!-- admissionTypeId -->
        <mat-form-field>
            <mat-label>Art der Einweisung</mat-label>
            <mat-select (ngModelChange)="editedInputs[path.id] = true;" class="form-control" id="admissionType" required
                name="admissionTypeId" [(ngModel)]="path.admissionTypeId">
                <mat-option *ngFor="let admissionType of admissionTypes"
                    [value]="admissionType.id">{{admissionType.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Aufnahmediagnose -->
        <mat-form-field style="width: 31%;" class="example-chip-list">
            <mat-chip-grid #chipADGrid aria-label="admission diagnosis selection">
                <mat-chip-row *ngFor="let adDiag of admissionDiagnosesArray; let i = index"
                    (removed)="deleteADDiagEntry(i); editedInputs[path.id] = true;"
                    (click)="showFullDiagnosis = !showFullDiagnosis">
                    {{ adDiag.code }} {{ showFullDiagnosis ? adDiag.name : (adDiag.name | slice:0:30) + '...' }}
                    <button matChipRemove [attr.aria-label]="'remove ' + adDiag.code">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-grid>
            <input id="matAutoInput"
                (ngModelChange)="searchAdDiagnoses(adDiagInput.value); editedInputs[path.id] = true;"
                placeholder="Aufnahmediagnose finden und auswählen" #adDiagInput [formControl]="adDiagnosesCtrl"
                [matChipInputFor]="chipADGrid" [matAutocomplete]="autoAD"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addAdmissionDiagnoses(adDiagInput.value, adDiagInput.value, $event)" />
            <mat-autocomplete #autoAD="matAutocomplete" (optionSelected)="selectedAd($event)">
                <mat-option *ngFor="let admissionDiagnoses of icds$ | async" [value]="admissionDiagnoses">
                    {{ admissionDiagnoses.code }} {{ admissionDiagnoses.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <!-- Hauptdiagnosen -->
        <mat-form-field style="width: 31%;" class="example-chip-list">
            <mat-chip-grid #chipGrid aria-label="main diagnosis selection">
                <mat-chip-row *ngFor="let mainDiag of additionalMainDiagnosesArray; let i = index"
                    (removed)="deleteMainDiagEntry(i); editedInputs[path.id] = true;"
                    (click)="showFullDiagnosis = !showFullDiagnosis">
                    {{ mainDiag.code }} {{ showFullDiagnosis? mainDiag.name : (mainDiag.name | slice:0:30) +
                    '...' }}
                    <button matChipRemove [attr.aria-label]="'remove ' + mainDiag.code">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-grid>
            <input id="matAutoInput"
                (ngModelChange)="searchMainDiagnoses(mainDiagInput.value); editedInputs[path.id] = true;"
                placeholder="Weitere Hauptdiagnosen finden und auswählen" #mainDiagInput
                [formControl]="mainDiagnosesCtrl" [matChipInputFor]="chipGrid" [matAutocomplete]="autoMain"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addAdditionalMainDiagnoses(mainDiagInput.value, mainDiagInput.value, $event)" />
            <mat-autocomplete #autoMain="matAutocomplete" (optionSelected)="selectedMain($event)">
                <mat-option *ngFor="let mainDiagnosis of icds$ | async" [value]="mainDiagnosis">
                    {{ mainDiagnosis.code }} {{ mainDiagnosis.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <!-- Nebendiagnosen -->
        <mat-form-field style="width: 31%;" class="example-chip-list">
            <mat-chip-grid #chipSideGrid aria-label="side diagnosis selection">
                <mat-chip-row *ngFor="let sideDiag of additionalSideDiagnosesArray; let i = index"
                    (removed)="deleteSideDiagEntry(i); editedInputs[path.id] = true;"
                    (click)="showFullDiagnosis = !showFullDiagnosis">
                    {{ sideDiag.code }} {{ showFullDiagnosis ? sideDiag.name : (sideDiag.name | slice:0:30) + '...'
                    }}
                    <button matChipRemove [attr.aria-label]="'remove ' + sideDiag.code">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-grid>
            <input id="matAutoInput"
                (ngModelChange)="searchDiagnoses(sideDiagInput.value); editedInputs[path.id] = true;"
                placeholder="Nebendiagnosen finden und auswählen" #sideDiagInput [formControl]="sideDiagnosesCtrl"
                [matChipInputFor]="chipSideGrid" [matAutocomplete]="autoSide"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addAdditionalSideDiagnoses(sideDiagInput.value, sideDiagInput.value, $event)" />
            <mat-autocomplete #autoSide="matAutocomplete" (optionSelected)="selectedSide($event)">
                <mat-option *ngFor="let sideDiagnosis of icds$ | async" [value]="sideDiagnosis">
                    {{ sideDiagnosis.code }} {{ sideDiagnosis.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="button-group">
            <button (click)="checkFormSaved()" style="color: white; background-color: #6b8294;" mat-button
                class="redirectButton">ABBRECHEN</button>
            <button (click)="saved(true)" *ngIf="this.ownPathService.getisNewPath() && !path.SID"
                style="color: white; background-color: #6b8294;" mat-button class="saveButton" type="submit">PATIENT
                ANLEGEN</button>
            <button *ngIf="!this.ownPathService.getisNewPath() || path.SID"
                style="color: white; background-color: #6b8294;" mat-button class="saveButton" type="submit">PATIENT
                EDITIEREN <mat-icon
                    [style.color]="editedInputs[path.id] && !newPatient ? 'red' : 'white'">save</mat-icon></button>
        </div>

    </form>

    <section hidden id="mainCard">
        <p style="margin-top: 5%;">Auf welchen Stationen wurde der Patient/die Patientin während des Aufenthalts
            behandelt?</p>

        <div *ngIf="pathUnitsBySFIDObjects.length >= 1">
            <ng-container *ngFor="let card of pathUnitsBySFIDObjects; let k = index">

                <mat-card style="margin-top: 2%;">

                    <!-- delete sfid -->
                    <button
                        (click)="popupText='Wollen sie die Fall-ID wirklich löschen?'; showDeleteSFID = true; SFIDtoDelete = card.SFID;"
                        id="deleteUnit"
                        style="color: white; background-color: #B9C8D2; width: 10%; margin-left: 90%; margin-bottom: 3%;"
                        mat-button>
                        <mat-icon class="material-symbols-rounded">delete</mat-icon>
                        LÖSCHEN
                    </button>

                    <form #unitForm="ngForm" name="unitForm" (ngSubmit)="onSubmitUnitForm(unitForm)">

                        <!-- create treatment unit -->
                        <!-- SFID -->
                        <mat-form-field id="sfid" style="width: 20%;">
                            <mat-label>Fall-ID</mat-label>
                            <input matInput id="sfidInput" readonly type="string" name="SFID" [(ngModel)]="card.SFID">
                        </mat-form-field>

                        <!-- Station -->
                        <mat-form-field class="example-full-width" style="width: 35%;">
                            <input id="matAutoInput" type="text" placeholder="Bitte Station eintragen"
                                aria-label="Station" matInput [formControl]="unitCtrl" [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field style="width: 20%;">
                            <mat-label>Startdatum</mat-label>
                            <input [matDatepicker]="startdatum" (change)="setEndDateToStartDate()" required matInput
                                id="startdatum" name="start" [(ngModel)]="card.start" [min]="path.admissionDate"
                                [max]="getCurrentDate()">
                            <mat-datepicker-toggle matIconSuffix [for]="startdatum"></mat-datepicker-toggle>
                            <mat-datepicker #startdatum></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field style="width: 20%;">
                            <mat-label>Enddatum</mat-label>
                            <input [matDatepicker]="end" required matInput id="enddatum" name="end"
                                [(ngModel)]="card.end" value="" [min]="path.admissionDate" [max]="getCurrentDate()">
                            <mat-datepicker-toggle matIconSuffix [for]="end"></mat-datepicker-toggle>
                            <mat-datepicker #end></mat-datepicker>
                        </mat-form-field>

                        <!-- add treatment unit button -->
                        <button [disabled]="!unitForm.valid" type="submit"
                            style="color: white; background-color: #B9C8D2;" mat-button id="addStation">
                            <mat-icon class="material-symbols-rounded">save</mat-icon>
                            Station hinzufügen
                        </button>

                    </form>

                    <mat-divider></mat-divider>

                    <div id="pathUnit" *ngFor="let unit of pathUnitsBySFIDObjects[k].units; let i = index">
                        <form #editForm="ngForm" name="editForm">
                            <!-- Render treatment units -->

                            <div style="display: none;" class="form-group">
                                <div class="input">
                                    <label for="fallID">Fall-ID</label>
                                    <input readonly type="string" name="SFID"
                                        [(ngModel)]="pathUnitsBySFIDObjects[k].SFID"><br>
                                </div>
                            </div>

                            <mat-form-field style="width: 30%;">
                                <mat-label>Station</mat-label>
                                <mat-select
                                    (ngModelChange)="editedInputs[unit.id] = true; editedTreatmentUnitId = unit.id"
                                    class="form-control" id="unitId" required name="unitId" [(ngModel)]="unit.unitId">
                                    <mat-option *ngFor="let unitItem of units" [value]="unitItem.id">
                                        {{ unitItem.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field style="width: 19%;">
                                <mat-label>Startdatum</mat-label>
                                <input [matDatepicker]="startdatum"
                                    (ngModelChange)="editedInputs[unit.id] = true; editedTreatmentUnitId = unit.id"
                                    required matInput id="startdatum" name="start" [(ngModel)]="unit.start"
                                    [min]="path.admissionDate" [max]="getCurrentDate()">
                                <mat-datepicker-toggle matIconSuffix [for]="startdatum"></mat-datepicker-toggle>
                                <mat-datepicker #startdatum></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field style="width: 19%;">
                                <mat-label>Enddatum</mat-label>
                                <input [matDatepicker]="end"
                                    (ngModelChange)="editedInputs[unit.id] = true; editedTreatmentUnitId = unit.id"
                                    required matInput id="enddatum" name="end" [(ngModel)]="unit.end"
                                    [min]="path.admissionDate" [max]="getCurrentDate()">
                                <mat-datepicker-toggle matIconSuffix [for]="end"></mat-datepicker-toggle>
                                <mat-datepicker #end></mat-datepicker>
                            </mat-form-field>

                            <!-- delete unit button -->
                            <button
                                (click)="popupText = 'Wollen sie die Station wirklich löschen?'; showDeleteUnit = true; unitToDelete = unit.id;"
                                id="deleteUnit"
                                style="color: white; background-color: #B9C8D2; bottom: 45px; margin-right: 1%; width: 14%;"
                                mat-button>
                                <mat-icon class="material-symbols-rounded">delete</mat-icon>
                                LÖSCHEN
                            </button>

                            <!-- Edit unit button -->
                            <button
                                (click)="editedTreatmentUnitId = unit.id; editedInputs[unit.id] = false; updateUnit(editForm, unit.id, i)"
                                id="editUnit" style="color: white; background-color: #B9C8D2; bottom: 45px; width: 14%;"
                                mat-button>
                                <mat-icon class="material-symbols-rounded" id="editPencil"
                                    [style.color]="(editedByPencil && editedTreatmentUnitId === unit.id) || editedInputs[unit.id] ? 'red' : 'white'">save</mat-icon>
                                SPEICHERN
                            </button>

                        </form>
                    </div>

                </mat-card>
            </ng-container>
        </div>

        <!-------------------------------------------------------------------------------------------------------------------------->

        <div *ngIf="pathUnitsBySFIDObjects.length == 0 || showCreateNewSFID;">

            <mat-card style="margin-top: 2%;">
                <form id="unitForm" #unitForm="ngForm" name="unitForm" (ngSubmit)="onSubmitUnitForm(unitForm)">

                    <!-- create treatment unit -->
                    <!-- SID -->
                    <mat-form-field id="sfid" style="width: 20%;">
                        <mat-label>Fall-ID</mat-label>
                        <input matInput id="sfidInput" readonly type="string" name="SFID" [(ngModel)]="pathUnits.SFID">
                    </mat-form-field>

                    <mat-form-field class="example-full-width" style="width: 35%;">
                        <input id="matAutoInput" type="text" placeholder="Bitte Station eintragen" aria-label="Station"
                            matInput [formControl]="unitCtrl" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field style="width: 20%;">
                        <mat-label>Startdatum</mat-label>
                        <input [matDatepicker]="startdatum" (change)="setEndDateToStartDate()" required matInput
                            id="startdatum" name="start" [(ngModel)]="pathUnits.start" [min]="path.admissionDate"
                            [max]="getCurrentDate()">
                        <mat-datepicker-toggle matIconSuffix [for]="startdatum"></mat-datepicker-toggle>
                        <mat-datepicker #startdatum></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field style="width: 20%;">
                        <mat-label>Enddatum</mat-label>
                        <input [matDatepicker]="end" required matInput id="enddatum" name="end"
                            [(ngModel)]="pathUnits.end" value="" [min]="path.admissionDate" [max]="getCurrentDate()">
                        <mat-datepicker-toggle matIconSuffix [for]="end"></mat-datepicker-toggle>
                        <mat-datepicker #end></mat-datepicker>
                    </mat-form-field>

                    <!-- add treatment unit button -->
                    <button [disabled]="!unitForm.valid" type="submit" style="color: white; background-color: #B9C8D2;"
                        mat-button id="addStation">
                        <mat-icon class="material-symbols-rounded">save</mat-icon>
                        Station hinzufügen
                    </button>

                </form>

            </mat-card>
        </div>

        <button (click)="addSFID()" style="color: white; background-color: #95BDD6; width: 20%;" mat-button
            id="addFallID">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            Weitere Fall-ID hinzufügen
        </button>

    </section>

</div>

<app-popup class="popup" *ngIf="showPopup == true" (answerEvent)="answerEvent($event)" [popupText]="popupText"
    [buttonLeftText]="''" [buttonRightText]="''" [buttonCenterText]="'OK'"> </app-popup>

<app-popup class="popup" *ngIf="showDeleteSFID == true" (buttonRightClicked)="reallyDeleteSFID()"
    (answerEvent)="answerEvent($event)" [popupText]="popupText" [buttonLeftText]="'ABBRECHEN'"
    [buttonRightText]="'OK'"></app-popup>

<app-popup class="popup" *ngIf="showDeleteUnit == true" (buttonRightClicked)="reallyDeleteUnit()"
    (answerEvent)="answerEvent($event)" [popupText]="popupText" [buttonLeftText]="'ABBRECHEN'"
    [buttonRightText]="'OK'"></app-popup>