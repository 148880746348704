import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BASE_PATH, EcmService, PatientArchiveService } from 'src/swagger-typescript';
import { environment } from 'src/environment/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ImprintComponent } from './imprint/imprint.component';
import { HeaderComponent } from './header/header.component';
import { AuthComponent } from './auth/auth.component';
import { PathsOverviewComponent } from './paths-overview/paths-overview.component';
import { NewPathComponent } from './new-path/new-path.component';
import { EntryKISComponent } from './entry-kis/entry-kis.component';
import { CreateActivityComponent } from './create-activity/create-activity.component';
import { PathViewComponent } from './path-view/path-view.component';
import { UserOrganizationComponent } from './user-organization/user-organization.component';
import { CreateUserAccountComponent } from './create-user-account/create-user-account.component';

// angular material modules
import { MultiSelectModule } from 'primeng/multiselect';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpRequestCookieInterceptor } from './http-request-cookie-interceptor.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

import { AuthService } from 'src/swagger-typescript/api/auth.service';
import { AdminService, AdmissionTypeService, IcdService, MandantService, PatientPathService, UserService, StateService, UnitService, HisService, HisEntryService, FacultyService, DataTransferService, DataQualityService, InformationCarrierService, DiagnosticService, DownloadService } from 'src/swagger-typescript';
import { HttpServerErrorInterceptor } from './http-server-error-interceptor.service';
import { LoggedInService } from './logged-in.service';
import { PopupComponent } from './popup/popup.component';
import { PatientArchiveComponent } from './patient-archive/patient-archive.component';
import { NewPatientArchiveDocumentComponent } from './new-patient-archive-document/new-patient-archive-document.component';
import { MenuComponent } from './menu/menu.component';
import { DropdownModule } from 'primeng/dropdown';
import { ContactComponent } from './contact/contact.component';
import {
  InformationCarrierAutoCompleteComponent
} from './create-activity/information-carrier-auto-complete/information-carrier-auto-complete.component';
import { DateAdapterService } from './date-adapter.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NetworkComponent } from './network/network.component';
import { DownloadsComponent } from './downloads/downloads.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    PrivacyComponent,
    ImprintComponent,
    HeaderComponent,
    PathsOverviewComponent,
    NewPathComponent,
    EntryKISComponent,
    CreateActivityComponent,
    PathViewComponent,
    UserOrganizationComponent,
    CreateUserAccountComponent,
    PopupComponent,
    PatientArchiveComponent,
    NewPatientArchiveDocumentComponent,
    MenuComponent,
    ContactComponent,
    DashboardComponent,
    NetworkComponent,
    DownloadsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatMenuModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatToolbarModule,
    MatGridListModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MultiSelectModule,
    DropdownModule,
    MatChipsModule,
    MatInputModule,
    InformationCarrierAutoCompleteComponent,
  ],
  providers: [
    AuthService,
    UserService,
    LoggedInService,
    AdmissionTypeService,
    AdminService,
    IcdService,
    MandantService,
    PatientPathService,
    StateService,
    UnitService,
    HisEntryService,
    FacultyService,
    HisService,
    DataTransferService,
    DataQualityService,
    DownloadService,
    InformationCarrierService,
    DiagnosticService,
    PatientArchiveService,
    EcmService,
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpRequestCookieInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpServerErrorInterceptor, multi: true
    },
    {
      provide: BASE_PATH, useValue: environment.apiURL
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'de-DE'
    },
    { provide: DateAdapter, useClass: DateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: { parse: { dateInput: 'dd.MM.yyyy' }, display: { dateInput: 'dd.MM.yyyy', monthYearLabel: 'MMM yyyy', dateA11yLabel: 'dd.MM.yyyy', monthYearA11yLabel: 'MMMM yyyy' } } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
