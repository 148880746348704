/**
 * Sodiaph Backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DownloadFilterRequest { 
    mandantIds?: Array<string>;
    admissionDiagnoseIds?: Array<string>;
    otherDiagnoseIds?: Array<string>;
    unitTypeIds?: Array<string>;
    admissionTypeIds?: Array<string>;
    treatmentDays?: Array<string>;
}