import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeneralService } from '../general.service';
import { environment } from 'src/environment/environment';
import { enableProdMode } from '@angular/core';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit {

  networkUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private generalService: GeneralService) {
    let url: string;
    if (environment.production) {
      url = environment.apiURL + '/netzwerkanalyse/';
    } else if (environment.title === 'Demo Environment') {
      url = 'https://demo.sodiaph.de/netzwerkanalyse/';
    } else {
      url = 'https://dev.sodiaph.de/netzwerkanalyse/';
    }
    this.networkUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

  }

  ngOnInit(): void {
    this.generalService.setTitle('Network Analysis');
    this.generalService.setSodiaphID('');
  }
}
