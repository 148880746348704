<header>
    <mat-toolbar class="tool">
        <button id="pathButton" [hidden]="pathButtonVisible" style="background-color: #6b8294;" mat-icon-button
            [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon class="material-symbols-rounded">menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="router.navigate(['./paths-overview']); resetData()">
                <mat-icon class="material-symbols-rounded">fork_right</mat-icon>
                <span>Übersicht Patientenpfade</span>
            </button>
            <button mat-menu-item (click)="router.navigate(['./dashboard']);">
                <mat-icon class="material-symbols-rounded">map</mat-icon>
                <span>Landkarte</span>
            </button>
            <button mat-menu-item (click)="router.navigate(['./network']);">
                <mat-icon class="material-symbols-rounded">insights</mat-icon>
                <span>Netzwerkanalyse</span>
            </button>
        </mat-menu>
        <img class="logo" src="assets\Logo_SODIAPH_weiß.png">
        <span>
            <p class="title">SODIAPH</p>
        </span>
        <h2>{{title}}</h2>
        <span class="example-spacer"></span>
        <span>
            <p class="title">{{sodiaphID}}</p>
        </span>
        <span class="example-spacer"></span>
        <span>
            <p class="title">{{username}}</p>
        </span>
        <button id="user" style="background-color: #6b8294;" mat-icon-button [matMenuTriggerFor]="user"
            aria-label="Example icon-button with a menu">
            <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
        </button>
        <mat-menu #user="matMenu">
            <button (click)="logoutService.logout()" mat-menu-item>
                <mat-icon class="material-symbols-rounded">logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </mat-toolbar>
</header>