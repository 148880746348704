import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, PatientPathDTO, PatientPathService } from 'src/swagger-typescript';
import { GeneralService } from '../general.service';
import { LoggedInService } from '../logged-in.service';
import { PathService } from '../path.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  title = '';
  sodiaphID = '';
  pathButtonVisible = false;
  username = '';

  constructor(private ownPathService: PathService, private generalService: GeneralService, public router: Router, private authService: AuthService, public logoutService: LoggedInService) {
  }

  ngOnDestroy(): void {
    this.generalService.pathButtonVisible.unsubscribe();
    this.generalService.title.unsubscribe();
    this.generalService.username.unsubscribe();
  }

  ngOnInit() {
    this.generalService.title.subscribe(title => {
      this.title = title;
    });

    this.generalService.pathButtonVisible.subscribe(pathButtonVisible => {
      this.pathButtonVisible = pathButtonVisible;
    });

    this.generalService.sodiaphID.subscribe(sodiaphID => {
      this.sodiaphID = sodiaphID;
    });

    this.generalService.username.subscribe(username => {
      this.username = username;
    });

  }

  resetData() {
    this.ownPathService.hisEntryDates = [];
    this.ownPathService.patientArchiveDates = [];
    this.ownPathService.setPath({});
    this.ownPathService.sfids = [];
  }
}