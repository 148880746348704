import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DownloadService } from 'src/swagger-typescript/api/download.service';
import { AdmissionType, DiagnosisDTO, DownloadPatientPathRequest, ICD, MandantDTO, PatientPathDownloadDTO, UnitDTO } from 'src/swagger-typescript';
import { MatPaginator } from '@angular/material/paginator';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  // Download-Konsole Daten
  filteredPaths!: MatTableDataSource<any>;
  displayedColumns: string[] = ['sodiaphId', 'klinik', 'icdAufnahmeDiagnose', 'weitereDiagnosen', 'station', 'verweildauer', 'auswahl'];

  // clinics
  clinics: MandantDTO[] = [];
  selectedKlinik: MandantDTO[] = [];

  // icds
  icdDiagnosen: DiagnosisDTO[] = [];
  selectedICD: DiagnosisDTO[] = [];

  // other diagnoses
  otherDiagnoses: DiagnosisDTO[] = [];
  selectedOtherDiagnoses: DiagnosisDTO[] = [];

  // unit types
  unitTypes: UnitDTO[] = [];
  selectedunitTypes: UnitDTO[] = [];

  // admission types
  admissionTypes: AdmissionType[] = [];
  selectedadmissionTypes: AdmissionType[] = [];

  // treatment days
  treatmentDays: string[] = [];
  selectedTreatmentDays: string[] = [];

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  constructor(private downloadService: DownloadService, private liveAnnouncer: LiveAnnouncer, public router: Router, public generalService: GeneralService) {
    this.loadData();
  }

  ngOnInit(): void {
    this.generalService.setTitle('Downloads');
  }

  loadData() {
    this.downloadService.v1DownloadDropdownsGet()
      .subscribe((response: any) => {
        // Extract mandants (IDs and names)
        this.clinics = response.mandants.map((mandant: any) => {
          return { id: mandant.id, name: mandant.name };
        });

        // Extract admissionDiagnoses (ICD-10 codes and names)
        this.icdDiagnosen = response.admissionDiagnoses.map((diagnosis: any) => {
          return { code: diagnosis.code, name: diagnosis.name };
        });

        // Extract otherDiagnoses (IDs and names)
        this.otherDiagnoses = response.otherDiagnoses.map((diagnosis: any) => {
          return { code: diagnosis.code, name: diagnosis.name };
        });

        // Extract unit types (IDs and names)
        this.unitTypes = response.unitTypes.map((unit: any) => {
          return { id: unit.id, name: unit.name };
        });

        // Extract admission types (IDs and names)
        this.admissionTypes = response.admissionTypes.map((admissionType: any) => {
          return { id: admissionType.id, name: admissionType.name };
        });

        // Extract treatment days
        this.treatmentDays = response.treatmentDays;
      });
  }

  checkSelection() {
    const requestBody = {
      mandantIds: this.selectedKlinik.map(selected => selected.id).filter((id): id is string => !!id),
      admissionDiagnoseIds: this.selectedICD.map(selected => selected.code).filter((code): code is string => !!code),
      otherDiagnoseIds: this.selectedOtherDiagnoses.map(selected => selected.code).filter((code): code is string => !!code),
      unitTypeIds: this.selectedunitTypes.map(selected => selected.id).filter((id): id is string => !!id),
      admissionTypeIds: this.selectedadmissionTypes.map(selected => selected.id).filter((id): id is string => !!id),
      treatmentDays: this.selectedTreatmentDays
    };

    this.downloadService.v1DownloadFilteredPatientPathsPost(requestBody)
      .subscribe({
        next: (response: PatientPathDownloadDTO[]) => {
          this.filteredPaths = new MatTableDataSource<any>(
            response.map(path => ({
              id: path.id,
              sodiaphId: path.SID,
              klinik: 'Unbekannt',
              icdAufnahmeDiagnose: path.diagnoses
                .filter(diagnose => diagnose.type === 'ADMISSION')
                .map(diagnose => diagnose.code)
                .join(', ') || 'Keine ICD-Aufnahme-Diagnose',
              weitereDiagnosen: path.diagnoses
                .filter(diagnose => diagnose.type !== 'ADMISSION')
                .map(diagnose => diagnose.code)
                .join(', ') || 'Keine weiteren Diagnosen',
              station: path.firstTreatmentUnitType?.name || 'Unbekannte Station',
              verweildauer: `${path.treatmentDays} Tage`,
              selected: true
            }))
          );

          // paginator
          if (this.filteredPaths.data.length !== 0 && this.paginator) {
            this.filteredPaths.paginator = this.paginator;
            this.paginator.length = this.filteredPaths.data.length;
            this.paginator.pageIndex = 0;
            this.paginator.pageSize = 15;
            this.paginator.page.emit();
          }
        },
        error: error => {
          console.error('Error:', error);
        }
      });
  }

  toggleAll(event: any): void {
    const checked = event.checked;

    const filteredPaths = this.filteredPaths.data;

    if (filteredPaths && Array.isArray(filteredPaths)) {
      filteredPaths.forEach(path => path.selected = checked);
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  downloadPaths(): void {
    const selectedPaths = this.filteredPaths.data.filter(path => path.selected);

    if (selectedPaths.length === 0) {
      console.warn('No paths selected for download.');
      return;
    }

    const requestBody = {
      patientPathIds: selectedPaths.map(path => path.id)
    };

    // Function to format the current date and time as a string
    const getTimestamp = () => {
      const now = new Date();
      return now.toISOString().replace(/[:-]/g, '').split('.')[0]; // Format: YYYYMMDDTHHMMSS
    };

    const timestamp = getTimestamp();

    // Download patient paths CSV
    this.downloadService.v1DownloadPatientPathCsvPost(requestBody)
      .subscribe({
        next: (response) => {
          FileSaver.saveAs(response, `patientpaths_${timestamp}.csv`);
        },
        error: (e) => { console.error(e); },
        complete: () => { console.info('Patient paths CSV download complete'); }
      });

    // Download treatment units CSV
    this.downloadService.v1DownloadTreatmentUnitCsvPost(requestBody)
      .subscribe({
        next: (response) => {
          FileSaver.saveAs(response, `treatmentunits_${timestamp}.csv`);
        },
        error: (e) => { console.error(e); },
        complete: () => { console.info('Treatment units CSV download complete'); }
      });

    // Download ICD CSV
    this.downloadService.v1DownloadIcdCsvPost(requestBody)
      .subscribe({
        next: (response) => {
          FileSaver.saveAs(response, `icd_${timestamp}.csv`);
        },
        error: (e) => { console.error(e); },
        complete: () => { console.info('ICD CSV download complete'); }
      });

    // Download HIS entries CSV
    this.downloadService.v1DownloadHisEntryCsvPost(requestBody)
      .subscribe({
        next: (response) => {
          FileSaver.saveAs(response, `hisentries_${timestamp}.csv`);
        },
        error: (e) => { console.error(e); },
        complete: () => { console.info('HIS entries CSV download complete'); }
      });

    // Download ECM entries CSV
    this.downloadService.v1DownloadEcmEntryCsvPost(requestBody)
      .subscribe({
        next: (response) => {
          FileSaver.saveAs(response, `ecmentries_${timestamp}.csv`);
        },
        error: (e) => { console.error(e); },
        complete: () => { console.info('ECM entries CSV download complete'); }
      });
  }

}