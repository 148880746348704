import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImprintComponent } from 'src/app/imprint/imprint.component'
import { PrivacyComponent } from 'src/app/privacy/privacy.component'
import { CreateActivityComponent } from './create-activity/create-activity.component';
import { CreateUserAccountComponent } from './create-user-account/create-user-account.component';
import { EntryKISComponent } from './entry-kis/entry-kis.component';
import { AuthComponent } from './auth/auth.component';
import { NewPathComponent } from './new-path/new-path.component';
import { PathViewComponent } from './path-view/path-view.component';
import { PathsOverviewComponent } from './paths-overview/paths-overview.component';
import { UserOrganizationComponent } from './user-organization/user-organization.component';
import { AuthGuardService } from './auth-guard.service';
import { PatientArchiveComponent } from './patient-archive/patient-archive.component';
import { NewPatientArchiveDocumentComponent } from './new-patient-archive-document/new-patient-archive-document.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NetworkComponent } from './network/network.component';
import { DownloadsComponent } from './downloads/downloads.component';


const routes: Routes = [
  { path: '', component: AuthComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'paths-overview', component: PathsOverviewComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'new-path', component: NewPathComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'entry-kis', component: EntryKISComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'create-activity', component: CreateActivityComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'network', component: NetworkComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'path-view', component: PathViewComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'user-organization', component: UserOrganizationComponent,
    canActivate: [AuthGuardService], data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create-user-account', component: CreateUserAccountComponent,
    canActivate: [AuthGuardService], data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'patient-archive', component: PatientArchiveComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'new-patient-archive-document', component: NewPatientArchiveDocumentComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService],
  },
  {
    path: 'downloads', component: DownloadsComponent,
    canActivate: [AuthGuardService], data: {
      expectedRole: 'admin'
    }
  },
];

// const routes: Routes = [
//   { path: '', component: AuthComponent },
//   { path: 'imprint', component: ImprintComponent },
//   { path: 'privacy', component: PrivacyComponent },
//   {
//     path: 'paths-overview', component: PathsOverviewComponent
//   },
//   {
//     path: 'new-path', component: NewPathComponent
//   },
//   {
//     path: 'entry-kis', component: EntryKISComponent
//   },
//   {
//     path: 'create-activity', component: CreateActivityComponent
//   },
//   {
//     path: 'path-view', component: PathViewComponent
//   },
//   {
//     path: 'user-organization', component: UserOrganizationComponent
//   },
//   {
//     path: 'create-user-account', component: CreateUserAccountComponent
//   },
//   {
//     path: 'patient-archive', component: PatientArchiveComponent
//   },
//   {
//     path: 'new-patient-archive-document', component: NewPatientArchiveDocumentComponent
//   },
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
