import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { DatePipe } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AdmissionType, AdmissionTypeService, PatientPathDTO, PatientPathService, UserService } from 'src/swagger-typescript';
import { MatPaginator } from '@angular/material/paginator';
import { PathService } from '../path.service';

@Component({
  selector: 'app-paths-overview',
  templateUrl: './paths-overview.component.html',
  styleUrls: ['./paths-overview.component.css']
})
export class PathsOverviewComponent implements OnInit {
  showPopup = false;
  popupText = '';

  paths = [] as Array<PatientPathDTO>;
  /** admission date is placeholder */
  displayedColumns: string[] = ['SID', 'updated', 'status', 'action'];
  dataSource!: MatTableDataSource<PatientPathDTO>;
  pipe = new DatePipe('de-DE');

  @ViewChild(MatSort, { static: false })
  sort: MatSort = new MatSort

  @ViewChild('SIDSearchInput') searchInput: any;

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  public admissionTypes = [] as Array<AdmissionType>;

  username = '';

  isPathClosed: { [key: string]: boolean } = {};
  pathStatus: { [key: string]: string } = {};

  constructor(private admissionTypeService: AdmissionTypeService, private userService: UserService, private ownPathService: PathService, private pathService: PatientPathService, public router: Router, private generalService: GeneralService, private liveAnnouncer: LiveAnnouncer) {
  }

  ngOnInit(): void {

    // request role of user who is logged in
    this.userService.v1UserOwnGet().subscribe({
      next: (v) => {
        this.username = v.username;
        this.generalService.setUser(this.username);
      },
      error: (e) => console.error("v1UserOwnGet - " + e),
      complete: () => {
      }
    });

    this.admissionTypeService.v1AdmissionTypeGet().subscribe(data => {
      this.admissionTypes = data;
    });
    this.generalService.setTitle('Übersicht Patientenpfade');
    this.generalService.setPathButtonVisible(false);
    this.generalService.setSodiaphID('');

    this.dataSource = new MatTableDataSource<PatientPathDTO>([]);

    this.pathService.v1PatientPathGet().subscribe({
      next: (v) => {
        this.paths = v;

        this.paths.forEach(path => {
          if (path.SID) {
            this.pathStatus[path.SID] = 'in Bearbeitung';
          }
        });

        this.dataSource.data = this.paths;
        this.dataSource.sort = this.sort;

        // Update the paginator
        this.dataSource.paginator = this.paginator;
        if (this.paths.length != 0 && this.paginator != undefined) {
          this.paginator.length = this.paths.length;
          this.paginator.pageIndex = 0;
          this.paginator.pageSize = 15;
          this.paginator.page.emit();
        }
      },
      error: (e) => { console.error(e) },
      complete: () => { }
    });

  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;

  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  loadPath(path: any) {
    this.ownPathService.sfids = [];

    this.ownPathService.isNewPathSubject.next(false);
    const data = {
      id: path.id, SID: path.SID, gender: path.gender, admissionDate: path.admission,
      age: path.age, admissionTypeId: path.admissionTypeId,
      diagnoses: path.diagnoses
    };
    this.ownPathService.setPath(data);
    this.router.navigate(['new-path']);
  }

  onNewPathClick(): void {
    this.ownPathService.isNewPathSubject.next(true);
  }

  fetchPaths(): void {
    const search = this.searchInput.nativeElement.value;
    if (search === "") {
      this.pathService.v1PatientPathGet().subscribe({
        next: (v) => {
          this.paths = v;
          this.dataSource.data = this.paths;
          this.dataSource.sort = this.sort;

          // Reset pagination to the first page
          this.paginator.firstPage();
        },
        error: (e) => {
          console.error(e);
        },
        complete: () => { }
      });
    } else {
      this.pathService.v1PatientPathFilterSearchGet(search).subscribe({
        next: (data: Array<PatientPathDTO>) => {
          const patientPaths: PatientPathDTO[] = data.map(dto => ({
            id: dto.id,
            admissionType: this.admissionTypes.find(type => type.id === dto.admissionTypeId),
            SID: dto.SID,
            gender: dto.gender,
            admissionDate: dto.admission,
            updated: dto.updated,
          }));

          this.paths = patientPaths;
          this.dataSource.data = this.paths;
          this.dataSource.sort = this.sort;

          // Reset pagination to the first page
          this.paginator.firstPage();
        },
        error: (e) => {
          console.error(e);
        }
      });
    }
  }

  closePath(element: any) {
    if (element.SID) {
      this.popupText = 'Dieses Feature ist noch nicht verfügbar';
      this.showPopup = true;
      // this.isPathClosed[element.SID] = !this.isPathClosed[element.SID];
      // this.pathStatus[element.SID] = 'Pfad abgeschlossen';
    }
  }

}